import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/generic/page-title"
import ContactForm from "../components/generic/contact-form"

const About = () => {
  const data = useStaticQuery(query)
  const dbaName = data.name.siteMetadata.company.name.dba
  const legalName = data.name.siteMetadata.company.name.legal

  return (
    <Layout>
      <SEO
        description="Learn about the best IT managed service provider serving Southern California."
        title="About Us"
      />
      <PageTitle title="About Us" />
      <div className="page-about">
        <div className="page-about__content-container">
          <div className="page-about__about-container">
            <div className="page-about__heading-container">
              <h2 className="heading-secondary heading-secondary--heading-underline-thin">
                <em>Mission</em> Statement
              </h2>
            </div>
            <div className="page-about__text-container">
              <p className="paragraph">
                {dbaName} is dedicated to providing businesses with{" "}
                <em>
                  proactive, predictable, and smart IT solutions that scale
                </em>{" "}
                with our member companies. We are{" "}
                <em>dedicated to constantly re-inventing ourselves</em> to
                support our member companies' ever-changing needs and
                challenges. We will work tirelessly to ensure that each of
                our member clients understands that{" "}
                <em>
                  building a mutually beneficial business relationship with us
                  is an investment
                </em>{" "}
                in a mutually prosperous future. Lastly, we are committed to
                <em>
                  {" "}
                  providing a unique and elite IT service for smart, discerning
                  businesses without ever compromising our quality
                </em>{" "}
                of service.
              </p>
            </div>
            <div className="page-about__heading-container">
              <h2 className="heading-secondary heading-secondary--heading-underline-thin">
                Operating <em>Philosophy</em>
              </h2>
            </div>
            <div className="page-about__text-container">
              <p className="paragraph">
                <em>IT should be easy for businesses!</em> Business owners and
                employees should spend very little time thinking about IT or
                dealing with IT problems. <em>IT should be proactive!</em> Most
                IT problems are predictable, and therefore can be either avoided
                or significantly mitigated. Not being proactive is neglect with
                potentially disastrous complications.{" "}
                <em>
                  IT decisions should be made by business owners/operators AND
                  IT experts
                </em>{" "}
                who understand IT is critical business infrastructure.
              </p>
            </div>
            <div className="page-about__heading-container">
              <h2 className="heading-secondary heading-secondary--heading-underline-thin">
                <em>Industries</em> We Serve
              </h2>
            </div>
            <div className="page-about__text-container">
              <p className="paragraph">
                <em>We can provide services to nearly every major industry.</em>{" "}
                Common industries include, but are not limited to: healthcare,
                construction, real estate, renting, leasing, state and local
                government, engineering, law, finance, insurance, social care,
                durable manufacturing, retail, wholesale, information, arts,
                entertainment, waste, utilities, corporate management,
                education, agriculture and more.
              </p>
            </div>
            <div className="page-about__heading-container">
              <h2 className="heading-secondary heading-secondary--heading-underline-thin">
                Overview of Our <em>History</em>
              </h2>
            </div>
            <div className="page-about__text-container">
              <p className="paragraph">
                {dbaName} is a DBA of {legalName}.{" "}
                <em>
                  We were founded by Blake Nesbit, a veteran of the information
                  technology industry.
                </em>{" "}
                Blake, at the time, was frustrated by the inefficiencies in IT
                service delivery and the negative outcomes they had on clients
                and engineers alike.
              </p>
              <p className="paragraph">
                Understanding that the foundation of any good relationship is
                trust,{" "}
                <em>
                  Blake worked to develop a new-generation, disruptive IT
                  managed service provider (MSP) based on trust.
                </em>{" "}
                He created <em>transparent business practices</em> and paired
                them with{" "}
                <em>cutting-edge technologies</em>, industry{" "}
                <em>best-practices</em>, performance audits and{" "}
                <em>analytics</em>, and an emphasis on{" "}
                <em>interoperability and automation</em>.
              </p>
              <p className="paragraph">
                Ultimately, Blake put these philosophies and ideas into practice
                in 2016 as {dbaName}. Since then{" "}
                <em>
                  we have been successfully growing, learning, and empowering
                  our member companies
                </em>
                . We have also created an environment where our{" "}
                <em>IT engineers can thrive in a creative space</em> and
                continue to{" "}
                <em>
                  enhance the quality of the service we provide to our valued
                  member businesses
                </em>
                .
              </p>
            </div>
          </div>
          <div className="page-about__contact-form">
            <ContactForm>
              <h2 className="heading-secondary">Get Started!</h2>
              <p className="paragraph">
                Fill out the form below to get started with a{" "}
                <em>FREE network infrastructure analysis</em>.
              </p>
            </ContactForm>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About

const query = graphql`
  query {
    name: site {
      siteMetadata {
        company {
          name {
            dba
            legal
          }
        }
      }
    }
  }
`
